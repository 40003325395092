class Beitragsrechner {
    constructor() {
        this.input = document.querySelector('.js-beitragsrechner-input');
        this.output = document.querySelector('.js-beitragsrechner-output');
        this.bemessungsgrundlageValue = document.querySelector('.js-beitragsrechner-value');

        if (!this.input || !this.output || !this.bemessungsgrundlageValue) {
            return;
        }

        this.input.addEventListener('input', this.change);

        this.change();
    }

    change = () => {
        try {
            this.output.innerHTML = `${Beitragsrechner.calculateCosts(Number(this.input.value))} €`;
            if (this.input.value > 120000) {
                this.bemessungsgrundlageValue.innerHTML = '> 120.000 €';
            } else {
                this.bemessungsgrundlageValue.innerHTML = `${Number(this.input.value).toLocaleString('de')} €`;
            }
        } catch (e) {
            console.error(e); // eslint-disable-line no-console
        }
    };

    static calculateCosts(bemessungsgrundlage) {
        switch (true) {
            case (bemessungsgrundlage <= 10000):
                return 35;
            case (bemessungsgrundlage <= 15000):
                return 75;
            case (bemessungsgrundlage <= 20000):
                return 105;
            case (bemessungsgrundlage <= 30000):
                return 125;
            case (bemessungsgrundlage <= 40000):
                return 155;
            case (bemessungsgrundlage <= 50000):
                return 180;
            case (bemessungsgrundlage <= 60000):
                return 205;
            case (bemessungsgrundlage <= 70000):
                return 220;
            case (bemessungsgrundlage <= 80000):
                return 235;
            case (bemessungsgrundlage <= 90000):
                return 275;
            case (bemessungsgrundlage <= 100000):
                return 325;
            case (bemessungsgrundlage <= 120000):
                return 385;
            case (bemessungsgrundlage >= 120000):
                return 400;
            default:
                throw new Error('Invalid Bemmessungsgrundlage');
        }
    }
}

// Dieses Objekt exponiert die API des Moduls nach Außen
// Die "init()" Funktion wie auch das instanziierte Objekt "exampleObject" können genutzt werden
// Kann auch die vollständige Funktionalität direkt beinhalten
const beitragsrechner = {
    init() {
        // Das Objekt sollte nur auf this gebunden werden, wenn es exponiert werden soll
        this.beitragsrechner = new Beitragsrechner();
    },
};

// Export der API
export default beitragsrechner;
